import React, { createContext, useContext, useMemo, useState } from 'react';

const PermissionsContext = createContext();

// Update PermissionsProvider
export const PermissionsProvider = ({ children, initialPermissions }) => {
  const [userPermissions, setUserPermissions] = useState(() => {
    // Load initial permissions from `initialPermissions` or `localStorage`
    return initialPermissions || JSON.parse(localStorage.getItem('userPermissions')) || null;
  });
       
  // Listen for changes in `localStorage`
  React.useEffect(() => {
    const handleStorageChange = () => {
      const updatedPermissions = JSON.parse(localStorage.getItem('userPermissions'));
      setUserPermissions(updatedPermissions);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const permissions = useMemo(() => {
    if (userPermissions === null) return { allowAll: true };
    if (Array.isArray(userPermissions) && userPermissions.length === 0) return {};
    return userPermissions.reduce((acc, item) => {
      acc[item.key] = true;
      return acc;
    }, {});
  }, [userPermissions]);

  return (
    <PermissionsContext.Provider value={{ permissions, setPermissions: setUserPermissions }}>
      {children}
    </PermissionsContext.Provider>
  );
};


export const usePermissions = () => {
  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error('usePermissions must be used within a PermissionsProvider');
  }
  return context;
};
