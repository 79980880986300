/* App settings */
import webSettings from '../settings.json';

let Settings = {
  ...process.env,
  ...webSettings,
};

Settings.format = {
  email:
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phonenumber: /^[0-9]{9,14}$/,
  dataNumber: /^(83){12}$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!$%@#£€*?&_=+-]{6,}$/,
  passwordMinLength: /^[a-zA-Z\d!$%@#£€*?&_=+-]{6,}$/,
  passwordOneUpper: /(?=.*[A-Z])/,
  passwordOneLower: /(?=.*[a-z])/,
  passwordOneDigit: /(?=.*\d)/,
  passportNumber: /^[a-zA-Z0-9]{1,20}$/, // Allows alphanumeric characters, 1 to 20 characters

  notEmptyString: /([^\s]*)/,
  landlinenumber: /^[0-9]{6,12}$/,
  denominationId: /^[0-9]{2,5}$/,
  passportNumber: /^[a-zA-Z0-9]{1,20}$/, // Allows alphanumeric characters, 1 to 20 characters

  date: /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/,
  idNumber: /^[0-9]{10}$/,
  identityId: /^[0-9]{7}$/,
  ICCID: /^[0-9]{19}$/,
  puk: /^[0-9]{8}$/,
  promoCode: /([^\s]*)/,
  digitOnly: /^[0-9\b]$/,
  saudiMobileKeys: {
    length9: /^(50|51|53|54|55|56|57|58|59)/,
    length10: /^(050|051|053|054|055|056|057|058|059)/,
    length12: /^(96650|96651|96653|96654|96655|96656|96657|96658|96659)/,
    length14:
      /^(0096650|0096651|0096653|0096654|0096655|0096656|0096657|0096658|0096659)/,
  },
  lebaraMobileKeys: {
    length9: /^(57)/,
    length10: /^(057)/,
    length12: /^(96657)/,
    length14: /^(0096657)/,
  },
  dataNumberPattern: {
    rule: /0?83\d{10}$/,
  },
  dataNumberInitPattern: /^(83|083)/,
  phoneNumberInitPattern: /^(5|05|9665|009665)/,
  phoneNumberPattern:/^(?:\+|)(5\d{8}|05\d{8}|9665\d{8}|009665\d{8})$/
,
  internationalNumber: /^(?:\+|00)[0-9]{8,14}$/,
  localNumber:
    /(^5\d{8}$)|(^05\d{8}$)|(^9665\d{8}$)|(^009665\d{8}$)|(^01[1-9]\d{7}$)/,
  minimumTwoChar: /^(?! ).{2,}$/,
  minimumTwentyChar: /^(?! ).{20,}$/,
  notEmpty: /^(?! ).{1,}$/,
  noSpacesAndNoSymbols: /^[^\s][a-zA-Z0-9\s]+[^\s]$|^[a-zA-Z0-9]+$/,
  noMoreThanOneSpace: /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/

  //
};

Settings.phoneNumberLength = 14;
Settings.seriesCurveColorTheme1 = '#0079D1';
Settings.seriesCurveColorTheme2 = '#D81268';
Settings.voucher=/^.{14}$/
Settings.get = function (setting) {
  return Settings[setting];
};

export default Settings;
