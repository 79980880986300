export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const LOGIN_OTP_START = 'LOGIN_OTP_START';
export const LOGIN_OTP_SUCCESS = 'LOGIN_OTP_SUCCESS';
export const LOGIN_OTP_FAILED = 'LOGIN_OTP_FAILED';

export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILED = 'REGISTER_FAILED';

export const RESET_ERROR = 'RESET_ERROR';

export const LOGOUT = 'LOGOUT';
export const SET_USER_INFO = 'SET_USER_INFO';

export const REQUEST_OTP_CODE_START = 'REQUEST_OTP_CODE_START';
export const REQUEST_OTP_CODE_SUCCESS = 'REQUEST_OTP_CODE_SUCCESS';
export const REQUEST_OTP_CODE_FAILED = 'REQUEST_OTP_CODE_FAILED';
export const CANCEL_REQUEST_OTP_CODE_SUCCESS = 'CANCEL_REQUEST_OTP_CODE_SUCCESS';

export const VALIDATE_OTP_CODE_START = 'VALIDATE_OTP_CODE_START';
export const VALIDATE_OTP_CODE_SUCCESS = 'VALIDATE_OTP_CODE_SUCCESS';
export const VALIDATE_OTP_CODE_FAILED = 'VALIDATE_OTP_CODE_FAILED';

export const BUNDLE_SUBSCRIBTION_RESET = 'BUNDLE_SUBSCRIBTION_RESET ';
export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';

export const SOCIAL_SIGNIN_START = 'SOCIAL_SIGNIN_START';
export const SOCIAL_SIGNIN_SUCCESS = 'SOCIAL_SIGNIN_SUCCESS';
export const SOCIAL_SIGNIN_FAILED = 'SOCIAL_SIGNIN_FAILED';


export const FETCH_SUB_ACCOUNTS_START = 'FETCH_SUB_ACCOUNTS_START';
export const FETCH_SUB_ACCOUNTS_SUCCESS = 'FETCH_SUB_ACCOUNTS_SUCCESS';
export const FETCH_SUB_ACCOUNTS_FAILED = 'FETCH_SUB_ACCOUNTS_FAILED';

export const SWITCH_ACCOUNT = 'SWITCH_ACCOUNT';
export const CHANGE_SUBSCRIBER = 'CHANGE_SUBSCRIBER';

export const RESET_ERRORS = 'RESET_ERRORS';

export const SOCIAL_SIGNUP_FAILED = 'SOCIAL_SIGNUP_FAILED';

export const GETTING_WALLETS_START = 'GETTING_WALLETS_START';
export const GETTING_WALLETS_SUCCESS = 'GETTING_WALLETS_SUCCESS';
export const GETTING_WALLETS_FAILED = 'GETTING_WALLETS_FAILED';

export const GETTING_TRANSFERABLE_DENOMINATIONS_START = 'GETTING_TRANSFERABLE_DENOMINATIONS_START';
export const GETTING_TRANSFERABLE_DENOMINATIONS_SUCCESS = 'GETTING_TRANSFERABLE_DENOMINATIONS_SUCCESS';
export const GETTING_TRANSFERABLE_DENOMINATIONS_FAILED = 'GETTING_TRANSFERABLE_DENOMINATIONS_FAILED';

export const SET_USER_DATA_INFO = 'SET_USER_DATA_INFO';

export const UPDATE_HISTORY_ACTIVE_FILTER = 'UPDATE_HISTORY_ACTIVE_FILTER';
export const UPDATE_AUTH_TOKEN = 'UPDATE_AUTH_TOKEN';
export const UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS';