import * as actions from './actionsType';
import { arrayToKeyedObject } from '../../util';
import * as constants from '../../constants';
import { getStorageElement } from 'services/storage.service';

let initialState = {
  auth_token: null,
  uuid: null,
  authToken: null,
  first_name: null,
  last_name: null,
  loggedIn: false,
  mobile_number: null,
  email: null,
  error: null,
  loading: false,
  registration_completed: false,
  requestOtpCodeSuccess: false,
  validateOtpCodeSuccess: false,
  resetPasswordSuccess: false,
  provider: null,
  errorKey: null, 
  providerToken: null,
  otp: null,
  redirectURL: null,
  reason: null,
  subscribersList: null,
  numberDetails: null,
  subscriptionType: null,
  selectedSubscriber: null,
  userWallets: null,
  userTransferableDenominations: null,
  historyActiveFilter: null,
  loginFailed: null
};
let userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_HISTORY_ACTIVE_FILTER:
      return {
        ...state,
 historyActiveFilter: {
      ...state.historyActiveFilter, // ✅ Keep previous filters
      ...action.filter, // Update only the active tab's filter
    },      };
    case actions.REGISTER_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case actions.REGISTER_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        registration_completed: true,
      };

    case actions.REGISTER_FAILED:
      return {
        ...state,
        error: action.errorMsg,
        loading: false,
        errorKey: action.errorMsgKey, // Store messageKey in the state

      };

    case actions.RESET_ERROR:
      return {
        ...state,
        error: null,
        loading: false,
        requestOtpCodeSuccess: false,
        validateOtpCodeSuccess: false,
        auth_token: null,
        first_name: null,
        last_name: null,
        mobile_number: null,
        email: null,
      };
    case actions.LOGIN_OTP_START:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case actions.LOGIN_OTP_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        subscribersList: action.subAccounts,
        selectedSubscriber: action.selectedSubscriber,
        loginFailed: false,
      };
    case actions.LOGIN_OTP_FAILED:
      return {
        ...state,
        error: action.errorMsg,
        loading: false,
        subscribersList: null,
      };
    case actions.LOGIN_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case actions.LOGIN_SUCCESS:
      let accountId = getStorageElement('subAccountId');
      let mainSubscriber = '';

      if (accountId) {
        mainSubscriber = action.subscribersList.filter((item) => {
          if (item.subscription_id == null) {
            item.subscription_id = constants.MAIN;
          }
          if (accountId == constants.MAIN) {
            return item;
          } else if (
            accountId == item.subscription_id &&
            item.subscription_id != constants.MAIN
          ) {
            return item;
          }
        });
      } else {
        mainSubscriber = action.subscribersList.filter(
          (item) => item.user.is_main
        );
      }

      let mainSubscriberData;

      if (mainSubscriber.length) {
        if (accountId == constants.MAIN || accountId == null) {
          mainSubscriber[0].subscription_id = constants.MAIN;
        }
        mainSubscriberData = mainSubscriber[0];
      } else {
        mainSubscriberData = null;
      }

      return {
        ...state,
        auth_token: action.auth_token,
        first_name: action.first_name,
        last_name: action.last_name,
        mobile_number: action.mobile_number,
        email: action.email,
        error: null,
        loading: false,
        loggedIn: true,
        userInfo: action.userInfo,
        subscribersList: action.subscribersList,
        subscriptionType: action.subscriptionType,
        selectedSubscriber: mainSubscriberData,
        loginFailed: false,
      };
    case actions.SET_USER_INFO:
      return {
        ...state,
        auth_token: action.auth_token,
        first_name: action.first_name,
        last_name: action.last_name,
        mobile_number: action.mobile_number,
        email: action.email,
        error: null,
        loading: false,
      };
    case actions.LOGIN_FAILED:
      return {
        ...state,
        error: action.errorMsg,
        loginFailed: true,
        loading: false,
        loggedIn: false,
      };
    case actions.LOGOUT:
      return {
        ...state,
        error: null,
        auth_token: null,
        first_name: null,
        last_name: null,
        loggedIn: false,
        mobile_number: null,
        email: null,
        registration_completed: false,
        requestOtpCodeSuccess: false,
        validateOtpCodeSuccess: false,
        resetPasswordSuccess: false,
      };

    case actions.REQUEST_OTP_CODE_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case actions.REQUEST_OTP_CODE_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        requestOtpCodeSuccess: true,
        numberDetails: action.numberDetails,
        mobile_number: action.mobile_number,

        auth_token: action.auth_token,
        first_name: action.first_name,
        last_name: action.last_name,
        email: action.email,
      };
    case actions.REQUEST_OTP_CODE_FAILED:
      return {
        ...state,
        error: action.errorMsg,
        loading: false,
        requestOtpCodeSuccess: false,
      };

    case actions.VALIDATE_OTP_CODE_START:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case actions.VALIDATE_OTP_CODE_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        validateOtpCodeSuccess: true,
        auth_token: action.auth_token,
        first_name: action.first_name,
        last_name: action.last_name,
        email: action.email,
        mobile_number: action.mobile_number,
        otp: action.otp,
        loggedIn: true,
        userInfo: action.userInfo,
      };
    case actions.VALIDATE_OTP_CODE_FAILED:
      return {
        ...state,
        error: action.errorMsg,
        loading: false,
        validateOtpCodeSuccess: false,
      };

    case actions.CHANGE_PASSWORD_START:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case actions.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        resetPasswordSuccess: true,
      };
    case actions.CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        error: action.errorMsg,
        loading: false,
      };

    case actions.SOCIAL_SIGNUP_FAILED:
      return {
        ...state,
        error: action.errorMsg,
        loading: false,
      };

    case actions.SOCIAL_SIGNIN_START:
      return {
        ...state,
        error: null,
        loading: true,
        provider: action.provider,
        providerToken: action.providerToken,
      };
    case actions.SOCIAL_SIGNIN_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        auth_token: action.auth_token,
        email: action.email,
        mobile_number: action.mobile_number,
        first_name: action.first_name,
        last_name: action.last_name,
        redirectURL: action.redirectURL,
      };

    case actions.SOCIAL_SIGNIN_FAILED:
      switch (action.reason) {
        case 'not_linked':
          return {
            ...state,
            error: action.errorMsg,
            loading: false,
            redirectURL: 'not_linked',
          };
        default:
          return {
            ...state,
            error: action.errorMsg,
            loading: false,
            redirectURL: null,
          };
      }

    case actions.RESET_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        validateOtpCodeSuccess: false,
        requestOtpCodeSuccess: false,
        otp: null,
      };

    case actions.BUNDLE_SUBSCRIBTION_RESET:
      return {
        ...state,
        requestOtpCodeSuccess: false,
        validateOtpCodeSuccess: false,
      };

    case actions.SWITCH_ACCOUNT:
      let subscribersList = arrayToKeyedObject(
        state.subscribersList,
        'subscription_id'
      );
      let selectedSubscriber = subscribersList[action.subAccountId];
      return {
        ...state,
        selectedSubscriber: selectedSubscriber,
      };

    case actions.FETCH_SUB_ACCOUNTS_START:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case actions.FETCH_SUB_ACCOUNTS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        subscribersList: action.subAccounts,
      };
    case actions.FETCH_SUB_ACCOUNTS_FAILED:
      return {
        ...state,
        error: action.errorMsg,
        loading: false,
        subscribersList: null,
      };
    case actions.GETTING_WALLETS_START:
      return {
        ...state,
        error: null,
      };
    case actions.GETTING_WALLETS_SUCCESS:
      return {
        ...state,
        error: null,
        userWallets: action.wallets,
      };

    case actions.GETTING_WALLETS_FAILED:
      return {
        ...state,
        error: action.errorMsg,
        userWallets: null,
      };
    case actions.GETTING_TRANSFERABLE_DENOMINATIONS_START:
      return {
        ...state,
        error: null,
      };
    case actions.GETTING_TRANSFERABLE_DENOMINATIONS_SUCCESS:
      return {
        ...state,
        error: null,
        userTransferableDenominations: action.denominations,
      };

    case actions.GETTING_TRANSFERABLE_DENOMINATIONS_FAILED:
      return {
        ...state,
        error: action.errorMsg,
        userTransferableDenominations: null,
      };

    case actions.SET_USER_DATA_INFO:
      return {
        ...state,
        userInfo: action.userInfo,
      };
    case actions.UPDATE_AUTH_TOKEN:
      return {
        ...state,
        auth_token: action.auth_token,
      };
      case actions.CANCEL_REQUEST_OTP_CODE_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          requestOtpCodeSuccess: false,
          numberDetails:null,
          mobile_number: null,
  
          auth_token: null,
          first_name: null,
          last_name: null,
          email: null,
        };
    default:
      return state;
  }
};
export default userReducer;
