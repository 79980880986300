import HTTP from './http.service';
class DashboardService {
  static getDashboard() {
    const config = {
      method: 'get',
      url: '/dashboard',
    };
    return HTTP(config);
  }
  static getRechargeHistory() {
    const config = {
      method: 'get',
      url: '/recharge/meter-history',
    };
    return HTTP(config);
  }

  static subscribeVas = (id, otp) => {
    const config = {
      method: 'post',
      url: `/vas-services/subscribe`,
      data: {
        id: id, // Service ID
        otp: otp, // OTP Code
      },
      headers: {
        'x-protocol-version': 'v3',
      },
    };
    return HTTP(config);
  };
  static subscribeVasServices = () => {
    const config = {
      method: 'get',
      url: `/vas-services/purchased?availability=web`,
      headers: {
        'x-protocol-version': 'v3',
      },
    };
    return HTTP(config);
  };
  static VasServices = (slug, category, page = 1, perPage = 5) => {
    const url = category
      ? `/vas-services/pages/${slug}?slug=${slug}&category=${category}&page=${page}&per_page=${perPage}`
      : `/vas-services/pages/${slug}?slug=${slug}&page=${page}&per_page=${perPage}`;

    const config = {
      method: 'get',
      url: url,
      headers: {
        'x-protocol-version': 'v3',
      },
    };

    return HTTP(config);
  };

  static getCategories = () => {
    const config = {
      method: 'get',
      url: `/vas-services/categories`,
      headers: {
        'x-protocol-version': 'v3',
      },
    };
    return HTTP(config);
  };
}

export default DashboardService;
